import TeamsFilterIcon from "@shared/svg/teams-filter.svg?react";

import {
  OpenFolderIcon,
  SidebarFacilityIcon,
  SidebarMachinesIcon,
  UserIcon,
  QrCodeIcon,
} from "~/icons/icons";

export const TICKET_STATUS = "TICKET_STATUS";
export const ASSIGNEE = "ASSIGNEE";
export const TICKET_TYPE = "TICKET_TYPE";
export const FACILITY_NAME = "FACILITY_NAME";
export const MACHINE_NAME = "MACHINE_NAME";
export const DATE_RANGE = "DATE_RANGE";
export const SCHEDULED = "SCHEDULED";
export const MACHINE_ASSIGNED = "MACHINE_ASSIGNED";
export const MACHINE_QR_CODE_ACCESS = "MACHINE_QR_CODE_ACCESS";
export const TEAMS = "TEAMS";

export const TicketStatusFilterObj = {
  id: TICKET_STATUS,
  name: "Ticket Status",
  icon: OpenFolderIcon,
};

export const AssigneeFilterObj = {
  id: ASSIGNEE,
  name: "Assignee",
  icon: UserIcon,
};

export const ScheduleFilterObj = {
  id: SCHEDULED,
  name: "Scheduled",
  icon: OpenFolderIcon,
  options: [{ value: "Yes" }, { value: "No" }],
};

export const TicketTypeFilterObj = {
  id: TICKET_TYPE,
  name: "Ticket Type",
  icon: OpenFolderIcon,
};

export const FacilityFilterObj = {
  id: FACILITY_NAME,
  name: "Facility Name",
  icon: SidebarFacilityIcon,
};

export const MachineFilterObj = {
  id: MACHINE_NAME,
  name: "Machine Name",
  icon: SidebarMachinesIcon,
};

export const DateRangeFilterObj = {
  id: DATE_RANGE,
  name: "Date Range",
  icon: OpenFolderIcon,
};

export const MachineAssignedFilterObj = {
  id: MACHINE_ASSIGNED,
  name: "Machine assigned",
  icon: SidebarMachinesIcon,
  options: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
};

export const MachineQRAccessFilterObj = {
  id: MACHINE_QR_CODE_ACCESS,
  name: "QR code enabled",
  icon: QrCodeIcon,
  options: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
};

export const TeamsFilterObj = {
  id: TEAMS,
  name: "Teams",
  icon: TeamsFilterIcon,
};

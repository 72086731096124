import Sidebar3DIconFilled from "@shared/svg/3d-active.svg?react";
import Sidebar3DIcon from "@shared/svg/3d.svg?react";
import AiIconFilled from "@shared/svg/ai-active.svg?react";
import AiIcon from "@shared/svg/ai-grey.svg?react";
import SidebarAnalyticsFilledIconV2 from "@shared/svg/analytics-active.svg?react";
import SidebarAnalyticsIconV2 from "@shared/svg/analytics.svg?react";
import AssetsManagementActive from "@shared/svg/assets-management-active.svg?react";
import AssetsManagement from "@shared/svg/assets-management.svg?react";
import CalendarBlankFilled from "@shared/svg/calendar-blank-filled.svg?react";
import CalendarBlank from "@shared/svg/calendar-blank.svg?react";
import SidebarFacilityFilledIconV2 from "@shared/svg/facilities-active.svg?react";
import SidebarFacilityIconV2 from "@shared/svg/facilities.svg?react";
import WorkManagementActive from "@shared/svg/work-management-active.svg?react";
import WorkManagement from "@shared/svg/work-management.svg?react";
import { getAllRoles } from "@shared/utils/getAllRoles";
import * as React from "react";
import { Redirect, useParams } from "react-router-dom";

import PAID_FEATURES from "$/settings/paid-features.json";
import { isOemAdmin } from "~/hooks/_useRoleManager";
import {
  EnvelopFilled,
  EnvelopOutline,
  InventoryFilledIcon,
  InventoryIcon,
  SidebarAnalyticsFilledIcon,
  SidebarAnalyticsIcon,
  SidebarAssetsFilledIcon,
  SidebarAssetsIcon,
  SidebarFacilityFilledIcon,
  SidebarFacilityIcon,
  SidebarMyTicketFilledIcon,
  SidebarMyTicketfolderIcon,
  SidebarOpenTicketsFilledIcon,
  SidebarOpenTicketsFolder,
  SidebarProceduresFilledIcon,
  SidebarProceduresIcon,
} from "~/icons/icons";
import { getEnums } from "~/utils";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const MyWorkOrdersPage = React.lazy(() =>
  import("~/containers/ticket/MyWorkOrdersPage"),
);
const WorkOrdersPage = React.lazy(() =>
  import("~/containers/ticket/WorkOrdersPage"),
);
const ProductionLines = React.lazy(() =>
  import("~/containers/Assets/ProductionLines"),
);
const Machines = React.lazy(() => import("~/containers/Assets/Machines"));
const MachineTemplates = React.lazy(() =>
  import("~/containers/Assets/MachineTemplates"),
);
const Components = React.lazy(() => import("~/containers/Assets/Components"));
const OemSingleTicket = React.lazy(() =>
  import("~/containers/OemSingleTicket"),
);
const MySingleTicket = React.lazy(() => import("~/containers/MySingleTicket"));
const MachineSingle = React.lazy(() => import("~/containers/MachineSingle"));
const MachineTemplateSingle = React.lazy(() =>
  import("~/containers/MachineTemplateSingle"),
);
const FacilityListPage = React.lazy(() =>
  import("~/containers/facility/FacilityListPage"),
);

const SingleFacility = React.lazy(() =>
  import("~/containers/facility/OemSingleFacility"),
);

const MainSettingsLayout = React.lazy(() =>
  import("./_mainSettingsLayout.jsx"),
);
const Analytics = React.lazy(() =>
  import("~/containers/analytics/Analytics.jsx"),
);

const Inventory = React.lazy(() =>
  import("~/containers/inventory/InventoryListPage.jsx"),
);

const Procedures = React.lazy(() =>
  import("~/containers/procedures/template/Procedures.jsx"),
);
const ProcedureSingle = React.lazy(() =>
  import("~/containers/procedures/template/ProcedureSingle.jsx"),
);
const ProcedureInstanceSingle = React.lazy(() =>
  import("~/containers/procedures/instance/ProcedureInstanceSingle.jsx"),
);

const ProductionLineSingle = React.lazy(() =>
  import("~/containers/machine/SingleProductionLine.jsx"),
);

const ComponentSingle = React.lazy(() =>
  import("~/containers/machine/SingleComponent.jsx"),
);

const Emails = React.lazy(() => import("~/containers/emails/Emails"));
const Email = React.lazy(() => import("~/containers/emails/Email"));
const AiAssistant = React.lazy(() => import("~/containers/AiAssistant"));
const AiNotetaker = React.lazy(() => import("~/containers/AiNotetaker"));
const ThreeDModel = React.lazy(() => import("~/containers/ThreeDModel"));

const ScheduleDispatch = React.lazy(() =>
  import("~/containers/scheduling/ScheduleDispatch"),
);

const ALL_ROLES = getAllRoles({
  getOEM: true,
  getCRM: false,
  getFacility: false,
});

const routes = [
  {
    id: "app",
    path: "/app/work-orders",
    exact: true,
    label: "Tickets",
    key: "openTickets",
    Main: () => {
      return <Redirect to="/app/work/work-orders" />;
    },
  },
  {
    id: "app",
    path: "/app/work/work-orders",
    exact: true,
    label: "Tickets",
    icon: <SidebarOpenTicketsFolder className="icon" />,
    iconActive: <SidebarOpenTicketsFilledIcon className="icon-active" />,
    iconV2: <WorkManagement className="icon h-3xl w-3xl flex-shrink-0" />,
    iconActiveV2: (
      <WorkManagementActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    key: "openTickets",
    tooltipKey: "workManagement",
    showInNavigation: true,
    Main: () => {
      return <WorkOrdersPage />;
    },
  },
  {
    id: "app",
    path: "/app/work-orders/:id",
    exact: true,
    label: "OEM Ticket",
    icon: "far fa-square",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/work-orders/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/work-orders/:id",
    exact: true,
    label: "OEM Ticket",
    icon: "far fa-square",
    Main: () => {
      return <OemSingleTicket />;
    },
  },
  {
    id: "app",
    path: "/app/my-work-orders",
    exact: true,
    key: "myTickets",
    label: "My Tickets",
    icon: <SidebarMyTicketfolderIcon className="icon" />,
    iconActive: <SidebarMyTicketFilledIcon className="icon-active" />,
    Main: () => {
      return <Redirect to="/app/work/my-work-orders" />;
    },
  },
  {
    id: "app",
    path: "/app/work/my-work-orders",
    exact: true,
    key: "myTickets",
    label: "My Tickets",
    icon: <SidebarMyTicketfolderIcon className="icon" />,
    iconActive: <SidebarMyTicketFilledIcon className="icon-active" />,
    Main: () => {
      return <MyWorkOrdersPage />;
    },
  },
  {
    id: "app",
    path: "/app/my-work-orders/:id",
    exact: true,
    label: "My Ticket",
    icon: "far fa-square",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/my-work-orders/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/my-work-orders/:id",
    exact: true,
    label: "My Ticket",
    icon: "far fa-square",
    Main: () => {
      return <MySingleTicket />;
    },
  },
  {
    id: "app",
    path: "/app/assets/machines",
    exact: true,
    label: "Machines",
    key: "machines",
    Main: () => <Machines />,
  },
  {
    id: "app",
    path: "/app/assets/machines/templates",
    exact: true,
    label: "Machine Templates",
    key: "machines",
    Main: () => {
      return <Redirect to="/app/assets/templates" />;
    },
  },
  {
    id: "app",
    path: "/app/assets/templates",
    exact: true,
    label: "Machine Templates",
    key: "machines",
    Main: () => <MachineTemplates />,
  },
  {
    id: "app",
    path: "/app/assets",
    exact: true,
    label: "Assets",
    icon: <SidebarAssetsIcon className="icon" />,
    iconActive: <SidebarAssetsFilledIcon className="icon-active" />,
    iconV2: <AssetsManagement className="icon h-3xl w-3xl flex-shrink-0" />,
    iconActiveV2: (
      <AssetsManagementActive className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    showInNavigation: true,
    key: "machines",
    Main: ({ user }) => {
      return (
        <Redirect
          to={
            user?.oem.paidFeatures.includes(paidFeatures.productionLines)
              ? "/app/assets/production-lines"
              : "/app/assets/machines"
          }
        />
      );
    },
  },
  {
    id: "app",
    path: "/app/assets/production-lines",
    exact: true,
    label: "Production Lines",
    key: "machines",
    Main: () => <ProductionLines />,
  },
  {
    id: "app",
    path: "/app/assets/components",
    exact: true,
    label: "Components",
    key: "machines",
    Main: () => <Components />,
  },
  {
    id: "app",
    path: "/app/assets/machines/templates/:id",
    exact: true,
    label: "Machine Template Details",
    key: "machineTemplateDetails",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/assets/templates/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/assets/templates/:id",
    exact: true,
    label: "Machine Template Details",
    key: "machineTemplateDetails",
    Main: () => {
      return <MachineTemplateSingle />;
    },
  },
  {
    id: "app",
    path: "/app/assets/machines/:id",
    exact: true,
    label: "Machines",
    icon: "fas fa-cogs",
    Main: () => {
      return <MachineSingle />;
    },
  },
  {
    id: "app",
    path: "/app/facilities",
    exact: true,
    label: "Facilities",
    icon: <SidebarFacilityIcon className="icon" />,
    iconActive: <SidebarFacilityFilledIcon className="icon-active" />,
    iconV2: (
      <SidebarFacilityIconV2 className="icon h-3xl w-3xl flex-shrink-0" />
    ),
    iconActiveV2: (
      <SidebarFacilityFilledIconV2 className="icon-active h-3xl w-3xl flex-shrink-0" />
    ),
    showInNavigation: true,
    key: "facilities",
    Main: () => {
      return <FacilityListPage />;
    },
  },
  {
    id: "app",
    path: "/app/facilities/:id",
    exact: true,
    label: "Facilities",
    icon: "fas fa-industry",
    Main: () => {
      return <SingleFacility />;
    },
  },
  {
    id: "app",
    path: "/app/settings",
    exact: true,
    Main: () => {
      return <Redirect to="/app/settings/company/general" />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company",
    exact: true,
    Main: () => {
      return <Redirect to="/app/settings/company/general" />;
    },
  },
  {
    id: "app",
    path: "/app/settings/personal",
    exact: true,
    Main: () => {
      return <Redirect to="/app/settings/personal/general" />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/general",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/time-tracker",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/notifications",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/customize-fields",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/users",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/data-management",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/access-permissions",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/branding",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/status-customization",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/email-configuration",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/email-configuration/success",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/work-order-customization",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/resource-management",
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
  },
  {
    id: "app",
    path: "/app/settings/personal/general",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/personal/notifications",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    enableIf: () => true,
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
    path: "/app/settings/personal/calendar-sync",
  },
  {
    path: "/app/settings/personal/calendar-sync/success",
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
  },
  {
    id: "app",
    path: "/app/procedures",
    exact: true,
    label: "Procedures",
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <Redirect to="/app/work/procedures" />;
    },
  },
  {
    id: "app",
    path: "/app/work/procedures",
    exact: true,
    label: "Procedures",
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <Procedures />;
    },
  },
  {
    id: "app",
    path: "/app/procedures/:id",
    exact: true,
    label: "Procedures",
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/procedures/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/procedures/:id",
    exact: true,
    label: "Procedures",
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <ProcedureSingle />;
    },
  },
  {
    id: "app",
    path: "/app/work-orders/:ticketId/procedure/:procedureId",
    exact: true,
    label: "Procedures",
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      const params = useParams();
      return (
        <Redirect
          to={`/app/work/work-orders/${params.ticketId}/procedure/${params.procedureId}`}
        />
      );
    },
  },
  {
    id: "app",
    path: "/app/work/work-orders/:ticketId/procedure/:procedureId",
    exact: true,
    label: "Procedures",
    icon: <SidebarProceduresIcon className="icon" />,
    iconActive: <SidebarProceduresFilledIcon className="icon-active" />,
    key: "procedures",
    Main: () => {
      return <ProcedureInstanceSingle />;
    },
  },
  {
    id: "app",
    path: "/app/inventory",
    exact: true,
    label: "Inventory",
    key: "inventory",
    icon: <InventoryIcon className="icon" />,
    iconActive: <InventoryFilledIcon className="icon-active" />,
    Main: () => <Redirect to="/app/assets/inventory" />,
  },
  {
    id: "app",
    path: "/app/assets/inventory",
    exact: true,
    label: "Inventory",
    icon: <InventoryIcon className="icon" />,
    iconActive: <InventoryFilledIcon className="icon-active" />,
    key: "inventory",
    Main: () => {
      return <Inventory />;
    },
  },
  {
    id: "app",
    path: "/app/assets/production-lines/:id",
    exact: true,
    key: "productionLineDetails",
    Main: () => {
      return <ProductionLineSingle />;
    },
  },
  {
    id: "app",
    path: "/app/assets/components/:id",
    exact: true,
    key: "componentDetails",
    Main: () => {
      return <ComponentSingle />;
    },
  },
  {
    id: "app",
    path: "/app/emails/:id",
    exact: true,
    label: "Emails",
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: () => {
      const params = useParams();
      return <Redirect to={`/app/work/emails/${params.id}`} />;
    },
  },
  {
    id: "app",
    path: "/app/work/emails/:id",
    exact: true,
    label: "Emails",
    enableIf: (user) => user.role !== ALL_ROLES.TECHNICIAN.VALUE,
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: () => {
      return <Email />;
    },
  },
  {
    id: "app",
    path: "/app/emails",
    exact: true,
    label: "Emails",
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: () => {
      return <Redirect to="/app/work/emails" />;
    },
  },
  {
    id: "app",
    path: "/app/work/emails",
    enableIf: (user) => user.role !== ALL_ROLES.TECHNICIAN.VALUE,
    exact: false,
    label: "Emails",
    icon: <EnvelopOutline className="icon" />,
    iconActive: <EnvelopFilled className="icon-active" />,
    key: "emails",
    Main: () => {
      return <Emails />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/users/accounts",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    id: "app",
    path: "/app/settings/company/users/teams",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    path: "/app/settings/company/users/skills",
    exact: true,
    Main: (props) => <MainSettingsLayout {...props} />,
  },
  {
    id: "app",
    path: "/app/settings/company/users/teams/:id",
    exact: true,
    Main: (props) => {
      return <MainSettingsLayout {...props} />;
    },
  },
  {
    exact: true,
    iconV2: <Sidebar3DIcon className="icon w-3xl h-3xl flex-shrink-0" />,
    iconActiveV2: (
      <Sidebar3DIconFilled className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
    key: "3d",
    Main: ThreeDModel,
    path: "/app/3d",
    showInNavigation: true,
  },
  {
    id: "app",
    path: "/app/ai",
    exact: true,
    label: "Industrial AI",
    iconV2: <AiIcon className="icon w-3xl h-3xl flex-shrink-0" />,
    iconActiveV2: (
      <AiIconFilled className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
    showInNavigation: true,
    key: "ai",
    Main: ({ user }) => (
      <Redirect
        to={
          !user?.oem.paidFeatures.includes(paidFeatures.aiAssistants) &&
          user?.oem.paidFeatures.includes(paidFeatures.aiNotetaker)
            ? "/app/ai/notetaker"
            : "/app/ai/assistant"
        }
      />
    ),
  },
  {
    exact: true,
    key: "ai-assistant",
    Main: AiAssistant,
    path: "/app/ai/assistant",
  },
  {
    exact: true,
    key: "ai-assistant",
    Main: () => <Redirect to="/app/ai/assistant" />,
    path: "/app/ai-assistant",
  },
  {
    exact: true,
    key: "ai-notetaker",
    Main: AiNotetaker,
    path: "/app/ai/notetaker",
  },
  {
    id: "app",
    enableIf: (user) => isOemAdmin(user?.role),
    path: "/app/analytics",
    showInNavigation: true,
    label: "Analytics",
    key: "analytics",
    icon: <SidebarAnalyticsIcon className="icon" />,
    iconActive: <SidebarAnalyticsFilledIcon className="icon-active" />,
    iconV2: (
      <SidebarAnalyticsIconV2 className="icon w-3xl h-3xl flex-shrink-0" />
    ),
    iconActiveV2: (
      <SidebarAnalyticsFilledIconV2 className="icon-active w-3xl h-3xl flex-shrink-0" />
    ),
    Main: () => {
      return <Analytics />;
    },
  },
  {
    exact: true,
    icon: <CalendarBlank className="icon w-5 h-5 text-secondary" />,
    iconActive: <CalendarBlankFilled className="icon-active w-5 h-5" />,
    enableIf: (user) => user.role !== ALL_ROLES.TECHNICIAN.VALUE,
    key: "scheduler",
    Main: () => <ScheduleDispatch />,
    path: "/app/work/scheduler",
  },
  {
    exact: true,
    enableIf: (user) => user?.oem.paidFeatures.includes(paidFeatures.scheduler),
    icon: <CalendarBlank className="icon w-5 h-5 text-secondary" />,
    iconActive: <CalendarBlankFilled className="icon-active w-5 h-5" />,
    key: "scheduler",
    Main: () => {
      return <Redirect to="/app/work/scheduler" />;
    },
    path: "/app/scheduler",
  },
];

export default (user) =>
  routes.filter(({ enableIf = () => true }) => enableIf(user));

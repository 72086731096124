import activityLog from "./activityLog.json";
import analytics from "./analytics";
import branding from "./branding";
import chat from "./chat";
import common from "./common";
import components from "./components.json";
import dataManagement from "./dataManagement";
import documents from "./documents";
import emails from "./emails.json";
import facilities from "./facilities";
import filters from "./filters";
import forms from "./forms";
import inventory from "./inventory";
import login from "./login";
import machines from "./machines";
import maintenanceDocuments from "./maintenanceDocuments";
import menus from "./menus";
import preventiveMaintenance from "./preventiveMaintenance";
import procedures from "./procedures";
import productionLines from "./productionLines.json";
import resourcePlanning from "./resourcePlanning";
import settings from "./settings";
import teams from "./teams.json";
import tickets from "./tickets";
import workOrderFormConfiguration from "./workOrderFormConfiguration.json";
import workOrderTypes from "./workOrderTypes.json";
import LOCALES from "../locales";

import aiAssistant from "~/lang/en-US/ai-assistant.json";
import aiNotetaker from "~/lang/en-US/ai-notetaker.json";
import resources from "~/lang/en-US/resources.json";
import scheduling from "~/lang/en-US/scheduling.json";

export default {
  [LOCALES[0].locale]: {
    analytics,
    chat,
    common,
    dataManagement,
    documents,
    facilities,
    forms,
    inventory,
    login,
    machines,
    maintenanceDocuments,
    menus,
    preventiveMaintenance,
    resourcePlanning,
    settings,
    tickets,
    branding,
    procedures,
    filters,
    productionLines,
    components,
    teams,
    emails,
    workOrderTypes,
    workOrderFormConfiguration,
    activityLog,
    aiAssistant,
    aiNotetaker,
    scheduling,
    resources,
  },
};

import APP_FEATURES from "$/settings/app-features.json";
import { getEnums } from "~/utils";

export const APP_FEATURES_ENUM = getEnums(APP_FEATURES, "reference");

export const DOCUMENT_FOLDERS = "documentFolders";
export const DEFAULT_PART_IMAGE_SRC = "/images/default/parts-thumbnail.svg";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const TIME_FORMAT = "hh:mm A";
export const DEFAULT_COLOR = "#0517F8";
export const BREADCRUMB_DEFAULT_TEXT_LIMIT = 30;
export const DROP_ANIMATION_ACTIVE_OPACITY = "0.5";

// Work order status
export const STATUS_BACKGROUND_COLOR_ALPHA = 0.1;
export const KANBAN_COLUMN_TITLE_BACKGROUND_COLOR_ALPHA = 0.3;
export const KANBAN_COLUMN_BACKGROUND_COLOR_ALPHA = 0.2;
export const KANBAN_COLUMN_STATUS_BACKGROUND_COLOR_ALPHA = 0.5;
export const CUSTOM_STATUS_MAX_CHARACTERS = 16;

// Custom Fields
export const CUSTOM_FIELD_MAX_NAME_LENGTH = 25;
export const CUSTOM_FIELD_SELECT_OPTION_LENGTH = 20;
export const CUSTOM_FIELD_MAX_NUMBER_LENGTH = 15;
export const CUSTOM_FIELD_MAX_TEXT_CONTENT_LENGTH = 100;
// table view
export const TABLE_VIEW_COLUMN_MIN_WIDTH = 60;
export const TABLE_VIEW_COLUMN_SIZE_SUFFIX = "_TABLE_COLUMN_SIZES";
export const TABLE_VIEW_ROW_HEIGHT = 42;

// machine list
export const MACHINE_LIST_VIEW_ROW_HEIGHT = 111;
export const MACHINE_TEMPLATE_LIST_VIEW_ROW_HEIGHT = 75;
export const ITEMS_PER_PAGE = 100;
export const ITEMS_BEFORE_PAGE = 2;

export const IMAGE_SIZE_LIMIT = 10485760;
export const MAX_TEXT_INPUT_LENGTH = 75;
export const MAX_SKILL_NAME_INPUT_LENGTH = 18;
export const PROCEDURE_NAME_MAX_INPUT_LENGTH = 150;
export const MAX_TEXT_DISPLAY_LENGTH = 75;

export const SUPPORT_EMAIL = "support@makula.io";
export const MD_MAX_WIDTH = 1024;

export const TEAM_NAME_LENGTH = 18;
export const PIXELS_PER_MM = 3.7795275591;
export const A4_WIDTH_IN_MM = 210;

export const TIME_FORMAT_24H = "HH:mm";

export const MAX_MULTIPART_REQUESTS = 10;

export const DEMO_3D_URL = "urn:x-i3d:examples:step:vtwin";

export const ASSIGNEES_AVATARS_MAX_LENGTH = 3;

export const THUMBNAIL_DIMENSIONS = "?width=50&height=38&format=png";
export const LARGE_THUMBNAIL_DIMENSIONS = "?width=160&height=120&format=png";

export const DATE_TIME_FORMAT = "YYYY-MM-DD hh:mm";

export const CSV_RESOURCES = {
  INVENTORY_PART: "InventoryPart",
  CUSTOMER: "Customer",
  TICKET: "Ticket",
  PRODUCTION_LINE: "ProductionLine",
  MACHINE: "Machine",
  COMPONENT: "Component",
  MACHINE_HISTORY: "MachineHistory",
};

export const CSV_DOWNLOADING_TOAST_ID = "csv-downloading-toast";
export const SLATE_EMPTY_TEXT =
  '[{"type":"paragraph","children":[{"text":""}]}]';

export const SENDING_EMAIL_TOAST_ID = "sending-email-toast";
export const API_RESPONSE_OK = "ok";

export const SORTING_ORDER = {
  ASCENDING: "asc",
  DESCENDING: "des",
};

export const INVENTORY_SORTING_KEY = {
  NAME: "name",
  ARTICLE_NUMBER: "articleNumber",
  DESCRIPTION: "description",
};

export const DOCUMENTS_TABS = {
  EXTERNAL: "external",
  INTERNAL: "internal",
};

export const DEBOUNCE_TIMEOUT = 100;

import { right } from "@popperjs/core";
import { TOAST_AUTO_CLOSE_DURATION } from "@shared/constants/index";
import Hamburger from "@shared/svg/hamburger.svg?react";
import HelpIconActive from "@shared/svg/help-active.svg?react";
import HelpIcon from "@shared/svg/help.svg?react";
import NotificationIconActive from "@shared/svg/notification-active.svg?react";
import NotificationIcon from "@shared/svg/notification.svg?react";
import SettingsFilledIcon from "@shared/svg/settings-active.svg?react";
import SettingsIcon from "@shared/svg/settings.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Overlay from "@shared/ui/Overlay";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";

import PAID_FEATURES from "$/settings/paid-features.json";
import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import { procedureFinalizing } from "~/atoms/_procedures";
import { setSidebarStateInStorage, sidebarAtom } from "~/atoms/sidebar";
import Avatar from "~/components/_avatar";
import AppVersion from "~/components/AppVersion";
import Loading from "~/components/general/_loading";
import useAuth from "~/components/general/_use-auth";
import { documentFoldersListener } from "~/components/header/DocumentFoldersListener";
import MainSettingsNavigation from "~/components/header/MainSettingsNavigation";
import SubNavigation from "~/components/header/SubNavigation";
import {
  CSV_DOWNLOADING_TOAST_ID,
  DOCUMENT_FOLDERS,
  DROMO_UPLOADING_TOAST_ID,
  EMAIL_ATTACHMENT_DOWNLOAD_IDENTIFIER,
  EMAIL_ATTACHMENT_DOWNLOADING_TOAST_ID,
  EMAIL_FOLDER_KEY,
  PROCEDURE_ATTACHING_TOAST_ID,
  PROCEDURE_FINALIZING_TOAST_ID,
  PROCEDURE_PDF_DOWNLOADING_TOAST_ID,
  PROCEDURE_TEMPLATE_DUPLICATING_TOAST_ID,
  PROCEDURE_UPDATE_IDENTIFIER,
  PROCEDURE_UPDATING_TOAST_ID,
  ROUTE_KEY_TO_NOTIFICATION_MAP,
  ROUTE_TO_KEY_MAP,
  routeHasSubRoutes,
  SENDING_EMAIL_TOAST_ID,
  SUB_ROUTES,
  useSubRoutes,
} from "~/constants";
import { NOTIFICATION_IDENTIFIERS } from "~/constants/notification-identifiers";
import {
  MEMBERSHIP_UPDATED_IDENTIFIER,
  WORK_ORDER_BROADCAST_TOPICS,
  WORK_ORDER_CREATED_IDENTIFIER,
  WORK_ORDER_DELETED_IDENTIFIER,
  WORK_ORDER_UPDATED_IDENTIFIER,
} from "~/constants/workOrders";
import useNotification from "~/hooks/_useNotification";
import { useRoleManager } from "~/hooks/_useRoleManager";
import useScrollPersist from "~/hooks/_useScrollPersist";
import {
  updateProcedureInstanceCacheOnFinalized,
  updateProcedureTemplateCacheOnDuplicate,
  updateTicketCacheOnProcedureAttached,
  useSendEmail,
} from "~/services";
import type { ChatType } from "~/types/chat";
import type { RouteType } from "~/types/route";
import type { UserType } from "~/types/user";
import { getEnums } from "~/utils";
import { broadcaster } from "~/utils/_appBroadcast";

const paidFeatures = getEnums(PAID_FEATURES, "reference");
const DEBOUNCE_PUBLISH_WAIT = 5000; // 5 seconds;

const SidebarNodeOverlay = ({
  route,
  sidebarExpanded,
  user,
  unreadLabels,
  pathname,
  onRouteClick,
  currentRoute,
  isSettingsMenu,
  tooltip,
}: {
  route: RouteType;
  sidebarExpanded: boolean;
  user: UserType;
  unreadLabels: { openTickets: number; myTickets: number };
  pathname: string;
  onRouteClick?: () => void;
  currentRoute: string;
  isSettingsMenu?: boolean;
  tooltip?: string;
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const nodeRef = useRef(null);
  const timeoutRef = useRef(null);
  const { messages } = useIntl();
  const { menus } = messages;
  const isSubRoutePath = routeHasSubRoutes(route.path);
  const [isMouseInOverlay, setIsMouseInOverlay] = useState(false);
  const subRoutes = useSubRoutes(route.path, user);

  const subRoutesLength = subRoutes?.length;

  const showSubNavigation =
    (isSubRoutePath || isSettingsMenu) && !sidebarExpanded;

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setShowOverlay(true);
  };

  const handleMouseLeave = (useTimeout = false) => {
    if (useTimeout && isMouseInOverlay)
      timeoutRef.current = setTimeout(() => {
        setShowOverlay(false);
      }, 200);
    else {
      setShowOverlay(false);
    }
  };

  useEffect(() => setShowOverlay(false), [pathname]);

  return (
    <>
      <div
        ref={nodeRef}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() =>
          handleMouseLeave(
            (isSubRoutePath || isSettingsMenu) && !sidebarExpanded,
          )
        }
      >
        {/* @ts-ignore */}
        <NavLink
          onClick={() => {
            onRouteClick?.();
          }}
          to={route.path}
          className={`navigation-link relative navigation-link-v2 ${
            currentRoute === route.path || showOverlay
              ? "is-navigation-link--active"
              : ""
          }`}
          activeClassName="is-navigation-link--active"
          data-for="navigation-hover-tooltip"
          data-event="mouseenter"
          data-event-off="mouseleave"
        >
          {route.iconV2}
          {route.iconActiveV2}
          {ROUTE_KEY_TO_NOTIFICATION_MAP[route?.tooltipKey ?? route.key]?.some(
            (notificationKey: string) => unreadLabels?.[notificationKey],
          ) ? (
            currentRoute === route.path ? (
              <NotificationIconActive className="absolute right-xs bottom-xs w-xs h-xs flex items-center justify-center" />
            ) : (
              <NotificationIcon className="absolute right-xs bottom-xs w-xs h-xs flex items-center justify-center" />
            )
          ) : null}
        </NavLink>
      </div>
      <Overlay
        arrowClasses={`${
          showSubNavigation
            ? "before:bg-primary shadow-xl"
            : "before:bg-secondary before:shadow-alert"
        }`}
        className={`ml-sm bg-primary w-fit z-10 ${
          showSubNavigation
            ? "bg-primary shadow-xl rounded-xl"
            : "bg-secondary shadow-alert rounded"
        }`}
        containerRef={document.body}
        offset={
          isSubRoutePath && !sidebarExpanded
            ? [subRoutesLength * 26, 20]
            : [0, 10]
        }
        placement={right}
        showOverlay={showOverlay}
        targetRef={nodeRef}
      >
        <div
          className="relative"
          onMouseEnter={() => {
            handleMouseEnter();
            setIsMouseInOverlay(true);
          }}
          onMouseLeave={() => {
            handleMouseLeave(false);
            setIsMouseInOverlay(false);
          }}
        >
          {showSubNavigation && (
            <>
              <div
                className={`absolute h-4xl ${
                  isSettingsMenu
                    ? "bottom-6xl w-2xl -left-xl"
                    : "-top-sm w-3xl -left-7"
                }`}
              />
              <div
                className={`absolute h-full ${
                  isSettingsMenu ? "-left-lg w-xl" : "-left-2xl w-2xl"
                }`}
              />
            </>
          )}
          {showSubNavigation ? (
            !isSettingsMenu ? (
              <SubNavigation
                route={route.path}
                sidebarExpanded={sidebarExpanded}
                pathname={pathname}
                user={user}
                parentRouteKey={route.tooltipKey ?? route.key}
                unreadLabels={unreadLabels}
                isOverlay
              />
            ) : (
              <div className="max-h-[95vh] overflow-y-auto">
                <MainSettingsNavigation isOverlay />
              </div>
            )
          ) : (
            <BodyText
              color="text-inverse"
              className="p-sm"
              size={BODY_TEXT_SIZES.X_SMALL}
            >
              {tooltip ?? menus?.[route?.tooltipKey ?? route?.key]?.["title"]}
            </BodyText>
          )}
        </div>
      </Overlay>
    </>
  );
};

const Navigation = ({
  routes,
  location,
  logout = () => {},
  user,
  toggleNavigation = () => {},
  appConfigLoading = true,
}: {
  routes: RouteType[];
  location: { pathname: string; key: string };
  logout: () => void;
  user: UserType;
  toggleNavigation: () => void;
  appConfigLoading: boolean;
}) => {
  const { messages } = useIntl();
  const { settings } = messages;
  const { pathname } = location || {};
  const { clearAll } = useScrollPersist();
  const { handleUpdateEmailCache } = useSendEmail();
  const [sidebarExpanded, setSidebarExpanded] = useRecoilState(sidebarAtom);
  const channelsRef = useRef({});
  const currentPathRef = useRef("");
  const pathMatcher = /^\/app\/work-orders\/(?<currentTicketId>.+)/;
  const matchedPath = pathname?.match(pathMatcher)?.groups;
  const isDocumentOff = !user?.oem?.paidFeatures?.includes(
    paidFeatures.documents,
  );
  const hasIntercomAppId = Boolean(
    (import.meta as ImportMeta & { env: { VITE_APP_INTERCOM_APP_ID: number } })
      .env.VITE_APP_INTERCOM_APP_ID,
  );
  const path = pathname === "/app" ? routes[0].path : pathname;
  const { unreadLabels } = useNotification();

  const subRouteMap: { [key: string]: string[] } = useMemo(() => {
    const map = {};
    Object.entries(SUB_ROUTES).map(([key, subRoutes]) => {
      map[key] = [];
      subRoutes.forEach((subRoute) => map[key].push(subRoute.route));
    });
    return map;
  }, []);

  useEffect(() => {
    let routeMapped = false;
    Object.entries(subRouteMap).some(([key, subRoutes]) => {
      return subRoutes.some((subRoute) => {
        if (path.startsWith(subRoute)) {
          setCurrentRoute(key);
          setCurrentRouteKey(ROUTE_TO_KEY_MAP[key]);
          routeMapped = true;
          return true;
        }
      });
    });
    if (!routeMapped) {
      setCurrentRoute(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const [currentRoute, setCurrentRoute] = useState(path);
  const [showHamburgerOverlay, setShowHamburgerOverlay] = useState(false);
  const [currentRouteKey, setCurrentRouteKey] = useState("");
  const [unreadChannelMessages, setUnreadChannelMessages] = useRecoilState(
    unreadChannelMessagesAtom,
  );
  const updateFinalizingInBackground = useSetRecoilState(procedureFinalizing);
  const nodeRef = useRef();

  const { chat: chatInstance } = useAuth() as {
    chat: ChatType;
  };

  const debouncePublishMembershipUpdated = debounce(() => {
    broadcaster.publish(WORK_ORDER_BROADCAST_TOPICS.MEMBERSHIP_UPDATED);
  }, DEBOUNCE_PUBLISH_WAIT);

  useEffect(() => {
    if (unreadChannelMessages?.channels) {
      channelsRef.current = unreadChannelMessages?.channels;
    }
    if (location) {
      currentPathRef.current = matchedPath?.currentTicketId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadChannelMessages, location?.key]);

  const shouldUpdateNotificationCount = (message) => {
    // no teams info provided with message
    if (!message?.userMetadata?.teams) return true;

    // has all teams access
    if (!user?.teams?.length) return true;

    const userTeams = user.teams.map((team) => team._id);
    const teamsInMessage = JSON.parse(message.userMetadata.teams);

    for (const team of userTeams) {
      if (teamsInMessage.includes(team)) {
        return true;
      }
    }

    return false;
  };

  const handleNewMessageNotification = useCallback(
    async (message) => {
      const existingChan = channelsRef.current?.[message?.channel];
      const currentUserIsPublisher = user?.chatUUID === message?.publisher;

      if (!shouldUpdateNotificationCount(message)) return;

      if (!currentUserIsPublisher && existingChan !== undefined) {
        setUnreadChannelMessages({
          channels: {
            ...channelsRef.current,
            [message.channel]: existingChan + 1,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unreadChannelMessages],
  );

  const oemMessageNotificationHandler = async (message) => {
    if (
      (message?.userMetadata?.isMachine ||
        message?.userMetadata?.isMachineTemplate ||
        message?.userMetadata?.isProductionLine ||
        message?.userMetadata?.isComponent ||
        message?.userMetadata?.isInventoryPart) &&
      message?.message?.text === DOCUMENT_FOLDERS
    ) {
      return documentFoldersListener(message);
    }

    if (
      message?.userMetadata?.isProcedureTemplate &&
      message?.message?.text ===
        NOTIFICATION_IDENTIFIERS.PROCEDURE_TEMPLATE_DUPLICATE
    ) {
      if (!message?.message?.success) {
        toast.update(PROCEDURE_TEMPLATE_DUPLICATING_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              type={TOAST_TYPES.ERROR}
              message={messages?.procedures?.["procedureDuplicateFailText"]}
            />
          ),
        });
        return;
      }
      const { payload } = message?.message || {};

      toast.update(PROCEDURE_TEMPLATE_DUPLICATING_TOAST_ID, {
        autoClose: TOAST_AUTO_CLOSE_DURATION,
        render: (
          <Toast
            message={messages?.procedures?.["procedureDuplicateSuccessText"]}
          />
        ),
      });

      try {
        await updateProcedureTemplateCacheOnDuplicate(payload);
      } catch (error) {
        console.error(error);
      }
    }
    if (
      message?.userMetadata?.isProcedureTemplate &&
      message?.message?.text === PROCEDURE_UPDATE_IDENTIFIER
    ) {
      if (!message?.message?.success) {
        toast.update(PROCEDURE_UPDATING_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              type={TOAST_TYPES.ERROR}
              message={
                messages?.procedures?.["errorUpdatingAttachedProcedures"]
              }
            />
          ),
        });
        return;
      }

      toast.update(PROCEDURE_UPDATING_TOAST_ID, {
        autoClose: TOAST_AUTO_CLOSE_DURATION,
        hideProgressBar: true,
        render: (
          <Toast
            message={
              messages?.procedures?.["successUpdatingAttachedProcedures"]
            }
          />
        ),
      });
    }
    if (
      message?.userMetadata?.isProcedure &&
      message?.message?.text === NOTIFICATION_IDENTIFIERS.PROCEDURE_ATTACH
    ) {
      if (!message?.message?.success) {
        toast.update(PROCEDURE_ATTACHING_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              type={TOAST_TYPES.ERROR}
              message={messages?.procedures?.["procedureAttachFailed"]}
            />
          ),
        });
        return;
      }
      const { payload } = message.message;
      try {
        const ticketCacheUpdated = await updateTicketCacheOnProcedureAttached(
          payload.workOrderId,
          payload.procedure,
          payload.addedBy,
        );

        if (ticketCacheUpdated) {
          toast.update(PROCEDURE_ATTACHING_TOAST_ID, {
            autoClose: TOAST_AUTO_CLOSE_DURATION,
            hideProgressBar: true,
            render: (
              <Toast
                message={messages?.procedures?.["procedureAttachSuccess"]}
              />
            ),
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (
      message?.userMetadata?.isProcedure &&
      message?.message?.text === NOTIFICATION_IDENTIFIERS.PROCEDURE_PDF_DOWNLOAD
    ) {
      if (!message?.message?.success) {
        toast.update(PROCEDURE_PDF_DOWNLOADING_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              type={TOAST_TYPES.ERROR}
              message={messages?.procedures?.["procedureDownloadFailed"]}
            />
          ),
        });
        return;
      }

      toast.dismiss(PROCEDURE_PDF_DOWNLOADING_TOAST_ID);

      const { _id: procedureId, url } = message.message.payload;
      const { uuid } = message.userMetadata;

      const pdfDownloadRequestUuid = sessionStorage.getItem(procedureId);

      if (pdfDownloadRequestUuid && uuid && pdfDownloadRequestUuid === uuid) {
        window.open(url, "_blank");
      }
    }

    if (
      message?.userMetadata?.isEmail &&
      message?.message?.text === NOTIFICATION_IDENTIFIERS.SEND_EMAIL
    ) {
      if (!message?.message.success) {
        toast.update(SENDING_EMAIL_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              type={TOAST_TYPES.ERROR}
              message={messages?.emails?.["sendEmailFailed"]}
            />
          ),
        });
        return;
      }

      toast.update(SENDING_EMAIL_TOAST_ID, {
        autoClose: TOAST_AUTO_CLOSE_DURATION,
        render: <Toast message={messages?.emails?.["emailSentSuccess"]} />,
      });
      handleUpdateEmailCache({ payload: message?.message?.payload });
    }

    if (
      message?.userMetadata?.isCsvExport &&
      message?.message?.text === NOTIFICATION_IDENTIFIERS.CSV_DOWNLOAD
    ) {
      if (!message?.message?.success) {
        toast.update(CSV_DOWNLOADING_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              type={TOAST_TYPES.ERROR}
              message={messages.common["csvDownloadFailed"]}
            />
          ),
        });
        return;
      }

      toast.dismiss(CSV_DOWNLOADING_TOAST_ID);

      const { uuid, url } = message.message.payload;

      const csvDownloadRequestUuid = sessionStorage.getItem(uuid);

      if (csvDownloadRequestUuid && uuid && csvDownloadRequestUuid === uuid) {
        window.open(url, "_blank");
      }
    }
    if (
      message?.userMetadata?.isProcedure &&
      message?.message?.text === NOTIFICATION_IDENTIFIERS.PROCEDURE_FINALIZE
    ) {
      if (!message?.message?.success) {
        toast.update(PROCEDURE_FINALIZING_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              message={messages?.procedures?.["instanceFinalizeSaveFail"]}
              type={TOAST_TYPES.ERROR}
            />
          ),
        });
        updateFinalizingInBackground(false);

        return;
      }

      const payload = message?.message?.payload;
      try {
        await updateProcedureInstanceCacheOnFinalized(payload);
        updateFinalizingInBackground(false);
        toast.update(PROCEDURE_FINALIZING_TOAST_ID, {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              message={messages?.procedures?.["instanceFinalizeSaveSuccess"]}
            />
          ),
        });
      } catch (error) {
        console.error(error);
        updateFinalizingInBackground(false);
      }
    }
    if (
      message?.userMetadata?.isFileImporter &&
      message?.message?.text === NOTIFICATION_IDENTIFIERS.DROMO_UPLOAD
    ) {
      const isSuccessful = message?.message?.success;
      toast.update(
        `${DROMO_UPLOADING_TOAST_ID}-${message?.message?.payload?.dromoId}`,
        {
          autoClose: TOAST_AUTO_CLOSE_DURATION,
          render: (
            <Toast
              message={
                isSuccessful
                  ? messages?.dataManagement?.["dataUploaded"]
                  : messages?.dataManagement?.["dataUploadFailed"]
              }
              type={isSuccessful ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR}
            />
          ),
        },
      );
    }

    if (
      message?.userMetadata?.isEmail &&
      message?.message?.text === EMAIL_ATTACHMENT_DOWNLOAD_IDENTIFIER
    ) {
      if (!message?.message?.success) {
        toast.update(EMAIL_ATTACHMENT_DOWNLOADING_TOAST_ID, {
          autoClose: 5000,
          render: (
            <Toast
              type={TOAST_TYPES.ERROR}
              message={messages?.procedures?.["procedureDownloadFailed"]}
            />
          ),
        });
        return;
      }

      toast.dismiss(EMAIL_ATTACHMENT_DOWNLOADING_TOAST_ID);
      toast.done(EMAIL_ATTACHMENT_DOWNLOADING_TOAST_ID);

      const { url } = message.message.payload;
      const { uuid } = message.userMetadata;

      const fileDownloadRequestUuid = sessionStorage.getItem(uuid);

      if (fileDownloadRequestUuid) {
        sessionStorage.removeItem(uuid);
        window.open(url, "_blank");
      }
    }
    if (
      message?.userMetadata?.isTicket &&
      (message?.message?.text === WORK_ORDER_UPDATED_IDENTIFIER ||
        message?.message?.text === WORK_ORDER_CREATED_IDENTIFIER)
    ) {
      const {
        oemId,
        ticketId,
        userId,
        ticketStatus = "",
        customerId,
      } = message.message.payload;
      if (
        userId &&
        userId === user?._id &&
        message?.message?.text !== WORK_ORDER_CREATED_IDENTIFIER
      )
        return;
      broadcaster.publish(
        WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
        ticketId,
        message?.message?.text === WORK_ORDER_CREATED_IDENTIFIER,
        false,
        ticketStatus,
      );
      // Add channels to unreadChannelMessages
      const ticketChannel = `channel-${oemId}-${customerId}-${ticketId}`;
      const internalTicketChannel = `channel-${oemId}-${ticketId}-internal-notes`;
      const channelsToAdd = {};
      if (customerId && channelsRef.current?.[ticketChannel] === undefined) {
        channelsToAdd[ticketChannel] = 0;
      }
      if (channelsRef.current?.[internalTicketChannel] === undefined) {
        channelsToAdd[internalTicketChannel] = 0;
      }
      setUnreadChannelMessages({
        channels: {
          ...channelsRef.current,
          ...channelsToAdd,
        },
      });
    }

    if (
      message?.userMetadata?.isTicket &&
      message?.message?.text === WORK_ORDER_DELETED_IDENTIFIER
    ) {
      const { ticketId, userId } = message.message.payload;
      if (userId && userId === user?._id) return;
      broadcaster.publish(
        WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
        ticketId,
        false,
        true,
      );
    }

    if (
      message?.userMetadata?.isTicket &&
      message?.message?.text === MEMBERSHIP_UPDATED_IDENTIFIER &&
      user?.chatUUID &&
      message?.message?.payload?.affectedMembers?.includes(user.chatUUID)
    ) {
      debouncePublishMembershipUpdated();
    }
  };

  const handleNavClick = () => {
    clearAll();
    toggleNavigation();
  };

  useEffect(() => {
    const listener = {
      message: handleNewMessageNotification,
      file: handleNewMessageNotification,
    };
    const oemMessageListener = { message: oemMessageNotificationHandler };

    chatInstance?.subscribe({
      channelGroups: [user?.notificationChannelGroupName],
      withPresence: false,
    });

    chatInstance?.subscribe({
      channels: [
        user?.notificationChannel,
        user?.organizationNotificationChannel,
      ],
      withPresence: false,
    });

    chatInstance?.addListener(listener);
    chatInstance?.addListener(oemMessageListener);

    return () => {
      chatInstance?.unsubscribe({
        channelGroups: [user?.notificationChannelGroupName],
      });
      chatInstance?.unsubscribe({
        channels: [
          user?.notificationChannel,
          user?.organizationNotificationChannel,
        ],
      });
      chatInstance?.removeListener(listener);
      chatInstance?.removeListener(oemMessageListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.notificationChannelGroupName,
    user?.chatToken,
    user?.notificationChannel,
    user?.organizationNotificationChannel,
  ]);

  useEffect(() => {
    if (!pathname.startsWith("/app/emails")) {
      localStorage.removeItem(EMAIL_FOLDER_KEY);
    }
  }, [pathname]);

  const { isOemStaff, isOemTechnician } = useRoleManager(user?.role);

  if (appConfigLoading) {
    return <Loading />;
  }

  const isBrandingEnabled =
    user?.oem?.paidFeatures.includes(paidFeatures.customerPortal) &&
    user?.oem?.paidFeatures.includes(paidFeatures.branding);

  const isCalendarSyncPaid = user.oem.paidFeatures.includes(
    paidFeatures.calendarSync,
  );

  return (
    <nav className="flex">
      <div className="w-6xl h-screen bg-secondary flex flex-col">
        <div className="w-6xl h-full block text-left">
          <div className="flex justify-between flex-col h-full">
            <ul className="list-none p-0 m-0 block pt-xs space-y-sm overflow-y-auto">
              <div
                className="flex w-full justify-center cursor-pointer"
                onClick={() => {
                  setSidebarStateInStorage(!sidebarExpanded);
                  setSidebarExpanded(!sidebarExpanded);
                }}
                onMouseEnter={() => setShowHamburgerOverlay(true)}
                onMouseLeave={() => setShowHamburgerOverlay(false)}
                ref={nodeRef}
              >
                <Hamburger className="w-3xl h-3xl flex-shrink-0 flex justify-center hover:bg-primary/10 rounded" />
              </div>
              <Overlay
                arrowClasses="before:bg-secondary before:shadow-alert"
                className="ml-sm w-fit z-10 bg-secondary shadow-alert rounded"
                containerRef={document.body}
                offset={[0, 10]}
                placement={right}
                showOverlay={showHamburgerOverlay}
                targetRef={nodeRef}
              >
                <BodyText
                  color="text-inverse"
                  className="p-sm"
                  size={BODY_TEXT_SIZES.X_SMALL}
                >
                  {sidebarExpanded
                    ? messages?.menus?.["hideMenu"]
                    : messages?.menus?.["showMenu"]}
                </BodyText>
              </Overlay>
              {routes.map((route: RouteType) => {
                if (route.path.startsWith("/app/documents") && isDocumentOff) {
                  return null;
                }
                return (
                  route.showInNavigation && (
                    <li key={route.path} className="flex justify-center px-sm">
                      <SidebarNodeOverlay
                        route={route}
                        sidebarExpanded={sidebarExpanded}
                        user={user}
                        unreadLabels={unreadLabels}
                        pathname={location.pathname}
                        onRouteClick={() => {
                          setCurrentRoute(route.path);
                          setCurrentRouteKey(route.key);
                          handleNavClick();
                        }}
                        currentRoute={currentRoute}
                      />
                    </li>
                  )
                );
              })}
            </ul>

            <div className="flex flex-col space-y-sm pb-sm">
              {hasIntercomAppId && (
                <div
                  className="navigation-link help-link justify-center"
                  data-for="navigation-hover-tooltip"
                  data-tip={messages?.common?.["help"]}
                  data-event="mouseenter"
                  data-event-off="mouseleave"
                >
                  <HelpIcon className="icon h-3xl w-3xl" />
                  <HelpIconActive className="icon-active h-3xl w-3xl" />
                </div>
              )}
              {/* @ts-ignore */}
              <div className="flex justify-center items-center">
                <SidebarNodeOverlay
                  route={{
                    path:
                      isCalendarSyncPaid && (isOemStaff || isOemTechnician)
                        ? "/app/settings/personal/calendar-sync"
                        : isBrandingEnabled
                        ? "/app/settings/company/branding"
                        : "/app/settings/company/general",
                    iconV2: (
                      <SettingsIcon className="icon w-3xl h-3xl flex-shrink-0" />
                    ),

                    iconActiveV2: (
                      <SettingsFilledIcon className="icon-active w-3xl h-3xl flex-shrink-0" />
                    ),
                    key: "",
                    tooltipKey: "",
                    showInNavigation: true,
                  }}
                  tooltip={settings?.["heroTitleV2"]}
                  isSettingsMenu
                  sidebarExpanded={sidebarExpanded}
                  user={user}
                  unreadLabels={unreadLabels}
                  pathname={location.pathname}
                  currentRoute={currentRoute}
                />
              </div>
              <div
                className="relative flex items-center w-full justify-center px-sm"
                style={{
                  zIndex: 1,
                }}
              >
                <div className="c-dropdown">
                  <Avatar
                    className="w-3xl h-3xl"
                    variant=""
                    name={user?.name}
                    email={user?.email}
                    showOverlay
                    role={user?.role}
                    oemName={user?.oem?.name}
                    logout={logout}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppVersion
          version={
            (import.meta as ImportMeta & { env: { PACKAGE_VERSION: string } })
              .env.PACKAGE_VERSION
          }
        />
      </div>
      <SubNavigation
        route={currentRoute}
        sidebarExpanded={sidebarExpanded}
        pathname={location.pathname}
        user={user}
        parentRouteKey={currentRouteKey}
        unreadLabels={unreadLabels}
      />
    </nav>
  );
};

export default Navigation;

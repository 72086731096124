import { useMutation } from "@apollo/client";

import { MachineTemplate } from "../../api";
import client from "../../apollo/_client";

import {
  ASSIGN_OWN_OEM_MULTIPLE_MACHINES_TO_OWN_OEM_CUSTOMER,
  ASSIGN_OWN_OEM_PARTS_TO_MACHINE_MUTATION,
  CREATE_OWN_OEM_MACHINE_MUTATION,
  DELETE_OEM_MACHINE_MUTATION,
  DELETE_OWN_OEM_MACHINE_IMAGE,
  DELETE_OWN_OEM_MACHINE_TEMPLATE_MUTATION,
  GET_OWN_OEM_CUSTOMER_BY_ID_QUERY,
  HANDLE_OWN_OEM_MACHINE_QR_ACCESS_MUTATION,
  REMOVE_OWN_OEM_MACHINE_FROM_OWN_OEM_CUSTOMER,
  REMOVE_OWN_OEM_PART_FROM_MACHINE_MUTATION,
  UPDATE_OWN_OEM_MACHINE_MUTATION,
  GET_OWN_OEM_MACHINE_BY_ID_QUERY,
  UPDATE_OWN_OEM_MACHINE_TEMPLATE_MUTATION,
  CREATE_OWN_OEM_MACHINE_TEMPLATE_MUTATION,
  ASSIGN_OWN_OEM_COMPONENTS_TO_MACHINE_MUTATION,
  UNASSIGN_OWN_OEM_COMPONENT_FROM_MACHINE_MUTATION,
  CREATE_MACHINE_HISTORY_NOTE_MUTATION,
  UPDATE_MACHINE_HISTORY_NOTE_MUTATION,
  DELETE_MACHINE_HISTORY_NOTE_MUTATION,
  MachineHistory,
  DELETE_OWN_OEM_MACHINE_3D_MODEL_MUTATION,
  ADD_OWN_OEM_MACHINE_3D_MODEL_MUTATION,
} from "~/api";
import { errorsHandler } from "~/transformers";

export const createMachine = () => {
  const [createOwnOemMachine, { error = {}, loading }] = useMutation(
    CREATE_OWN_OEM_MACHINE_MUTATION,
  );

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return createOwnOemMachine({
          variables: {
            input: {
              ...data,
            },
          },
          update: (cache, { data: { createOwnOemMachine: machine } }) => {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemMachines",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const updateMachine = () => {
  const [updateOwnOemMachine, { error = {}, loading }] = useMutation(
    UPDATE_OWN_OEM_MACHINE_MUTATION,
  );

  return {
    mutation: (data, evictCustomerCache = false) =>
      new Promise((resolve, reject) => {
        return updateOwnOemMachine({
          variables: {
            input: {
              ...data,
            },
          },
          update(cache) {
            if (evictCustomerCache)
              cache.evict({
                id: "ROOT_QUERY",
                fieldName: "getOwnOemCustomerById",
              });

            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemMachines",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemAllTickets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const deleteMachine = () => {
  const [remove, { loading, error }] = useMutation(DELETE_OEM_MACHINE_MUTATION);

  return {
    loading,
    error,
    removeMachine: async (machineId) =>
      new Promise((resolve, reject) =>
        remove({
          variables: { machineId },
          skip: !machineId,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listAllOwnOemMachines",
            });
            cache.gc();
          },
          refetchQueries: [GET_OWN_OEM_CUSTOMER_BY_ID_QUERY],
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};

export const handleOwnOemMachineQRAccess = () => {
  const [handleOwnOemMachineQRAccess, { error = {}, loading }] = useMutation(
    HANDLE_OWN_OEM_MACHINE_QR_ACCESS_MUTATION,
  );

  return {
    mutation: ({ machineId, isQRCodeEnabled }) =>
      new Promise((resolve, reject) => {
        return handleOwnOemMachineQRAccess({
          variables: {
            input: {
              machineId,
              isQRCodeEnabled,
            },
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const assignParts = () => {
  const [add, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_PARTS_TO_MACHINE_MUTATION,
  );

  return {
    addNewParts: async (input) =>
      add({
        variables: { input },
        skip: !input?.machineId,
        update(cache, { data: { assignOwnOemInventoryPartsToMachine: ref } }) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemMachineById",
          });
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const assignComponentsToMachine = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_COMPONENTS_TO_MACHINE_MUTATION,
  );

  return {
    error,
    assigningComponents: loading,
    assignComponents: async (input) =>
      assign({
        variables: { input },
        skip: !input?.machine,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemComponents",
          });
          cache.gc();
        },
      }),
  };
};

export const unassignComponentFromMachine = () => {
  const [remove, { loading, error }] = useMutation(
    UNASSIGN_OWN_OEM_COMPONENT_FROM_MACHINE_MUTATION,
  );

  return {
    loading,
    error,
    unassignComponent: async (input) =>
      remove({
        variables: { input },
        skip: !input?.machine || !input?.component,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemComponents",
          });
          cache.gc();
        },
      }),
  };
};

export const removeMachinePart = () => {
  const [remove, { loading, error }] = useMutation(
    REMOVE_OWN_OEM_PART_FROM_MACHINE_MUTATION,
  );

  return {
    removePart: async (input) =>
      remove({
        variables: { input },
        skip: !input?.machineId || !input?.partId,
        update(cache, { data: { removeOwnOemInventoryPartFromMachine: ref } }) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemMachineById",
          });
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const unassignMachineFromFacility = () => {
  const [remove, { loading, error }] = useMutation(
    REMOVE_OWN_OEM_MACHINE_FROM_OWN_OEM_CUSTOMER,
  );

  return {
    loading,
    error,
    unassignMachine: async (input) =>
      remove({
        variables: { input },
        skip: !input?.customer || !input?.machine,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemCustomerById",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });
          cache.gc();
        },
      }),
  };
};

export const assignMachinesToFacilty = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_OWN_OEM_MULTIPLE_MACHINES_TO_OWN_OEM_CUSTOMER,
  );

  return {
    error,
    assigningMachines: loading,
    assignMachines: async (input) =>
      assign({
        variables: { input },
        skip: !input?.customer,
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "getOwnOemCustomerById",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listAllOwnOemMachines",
          });
          cache.gc();
        },
      }),
  };
};

export const updateMachineCache = (payload) => {
  const machine = client.readQuery({
    query: GET_OWN_OEM_MACHINE_BY_ID_QUERY,
    variables: { id: payload._id },
  });

  if (!machine) return;

  const { getOwnOemMachineById: cachedMachine } = machine;
  if (cachedMachine) {
    const cloned = { ...cachedMachine };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_OEM_MACHINE_BY_ID_QUERY,
      variables: { id: payload._id },
      data: {
        getOwnOemMachineById: cloned,
      },
    });
  }
};

export const useDeleteMachineImage = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OWN_OEM_MACHINE_IMAGE,
  );

  return {
    loading,
    error,
    deleteMachineImage: async (machineId) =>
      remove({
        variables: { machineId },
      }),
  };
};

export const useDeleteMachine3DModel = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OWN_OEM_MACHINE_3D_MODEL_MUTATION,
  );

  return {
    loading,
    error,
    deleteMachine3DModel: async (machineId) =>
      remove({
        variables: { machineId },
      }),
  };
};

export const useAddMachine3DModel = () => {
  const [add, { loading, error }] = useMutation(
    ADD_OWN_OEM_MACHINE_3D_MODEL_MUTATION,
  );

  return {
    loading,
    error,
    addMachine3DModel: async ({ _id, _3dModelUrl }) =>
      add({
        variables: { id: _id, input: _3dModelUrl },
      }),
  };
};

export const useUpdateMachineTemplate = () => {
  const [updateMachineTemplate, { loading, error }] = useMutation(
    UPDATE_OWN_OEM_MACHINE_TEMPLATE_MUTATION,
  );

  return {
    loading,
    error,
    updateTemplate: async (input) =>
      new Promise((resolve, reject) => {
        return updateMachineTemplate({
          variables: { input },
          update(cache, { data: { updateOwnOemMachineTemplate: ref } }) {
            cache.modify({
              id: cache.identify({
                __typename: "MachineTemplate",
                ref: ref._id,
              }),
              fields: {
                getOwnOemMachineTemplate() {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: MachineTemplate.fragments.MachineTemplateData,
                    fragmentName: "MachineTemplateData",
                  });

                  return newRef;
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useDeleteMachineTemplate = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OWN_OEM_MACHINE_TEMPLATE_MUTATION,
  );

  return {
    loading,
    error,
    deleteTemplate: async (templateId) =>
      remove({
        variables: { templateId },
        update(cache) {
          cache.modify({
            fields: {
              listOwnOemMachineTemplates(existingRefs = [], { readField }) {
                return [...existingRefs].filter((ref) => {
                  return readField("_id", ref) !== templateId;
                });
              },
            },
          });
        },
      }),
  };
};

export const useCreateOwnOemMachineTemplate = () => {
  const [create, { loading, error }] = useMutation(
    CREATE_OWN_OEM_MACHINE_TEMPLATE_MUTATION,
  );

  return {
    loading,
    error,
    createMachineTemplate: async (input) =>
      new Promise((resolve, reject) => {
        return create({
          variables: { input },
          update(cache, { data: { createOwnOemMachineTemplate: ref } }) {
            cache.modify({
              fields: {
                listOwnOemMachineTemplates(existingRef = []) {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: MachineTemplate.fragments.MachineTemplateData,
                    fragmentName: "MachineTemplateData",
                  });

                  return [...existingRef, newRef];
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
  };
};

export const useCreateMachineHistoryNote = () => {
  const [createMachineHistoryNote, { error = {}, loading }] = useMutation(
    CREATE_MACHINE_HISTORY_NOTE_MUTATION,
  );
  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return createMachineHistoryNote({
          variables: {
            input: {
              ...data,
            },
          },
          update: (cache, { data: { createMachineHistoryNote } }) => {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemMachineHistory",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useUpdateMachineHistoryNote = () => {
  const [updateMachineHistoryNote, { error = {}, loading }] = useMutation(
    UPDATE_MACHINE_HISTORY_NOTE_MUTATION,
  );

  return {
    mutation: (data) =>
      new Promise((resolve, reject) => {
        return updateMachineHistoryNote({
          variables: {
            input: {
              ...data,
            },
          },

          update(cache, { data: { updateMachineHistoryNote: ref } }) {
            cache.modify({
              id: cache.identify({
                __typename: "MachineHistory",
                id: ref._id,
              }),
              fields: {
                listOwnOemMachineHistory(existingRefs = []) {
                  const newRef = cache.writeFragment({
                    data: ref,
                    fragment: MachineHistory.fragments.MachineHistoryData,
                    fragmentName: "MachineHistoryData",
                  });

                  return [...existingRefs, newRef];
                },
              },
            });
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(errorsHandler(err));
          });
      }),
    loading,
    error,
  };
};

export const useDeleteMachineHistoryNote = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_MACHINE_HISTORY_NOTE_MUTATION,
  );

  return {
    loading,
    error,
    removeHistoryNote: async (historyNoteId, machineId) =>
      new Promise((resolve, reject) =>
        remove({
          variables: { id: historyNoteId, machine: machineId },
          skip: !historyNoteId,
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemMachineHistory",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemActivityLogs",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "getOwnAiNote",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};
